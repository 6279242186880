<template>
    <div class="download-page">
        <div class="wrap">
            <div class="box">
                <div class="info">
                    <div class="title">{{title}}</div>
                    <div class="z-flex z-flex-center">
                        <span class="time">时间：{{time}}</span>
                        <span class="views">浏览次数：{{views}}</span>
                    </div>
                </div>
                <div class="desc" v-html="desc"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            
        },
        data() {
            return {
                title:'',
                time:'',
                views: '',
                desc:'',
            };
        },
        mounted() {
            this.$axios.api.downHelp({id: this.$route.params.id}, (res) => {
                if (res.code == 0) {
                    this.title = res.data.title;
                    this.desc = res.data.announcement;
                    this.views = res.data.view_times;
                    this.time = res.data.created_at;
                } else {
                    console.log(res.msg);
                }
            });
        },
        methods: {

        },
    };
</script>

<style lang="scss" scoped>
.breadcrumb{
    margin-top:30px;
    font-size: 16px;
    ::v-deep .el-breadcrumb__inner{
        color: #777;
        &.is-link:hover{
           color: $colorMain; 
        }
    }
}
.box{
    width:calc(100% - 60px);
    padding:20px 30px;
    background:#fff;
    border-radius: 4px;
    margin-top: 30px;
    .info{
        padding:20px 0;
        border-bottom: $borderDashed;
        text-align: center;
        color: #999;
        font-size: 14px;
        .title{
            color: #333;
            font-size: 28px;
            margin-bottom: 15px;
        }
        .time{
            margin-right: 30px;
        }
    }
    .desc{
        padding:30px 0 0;
        ::v-deep p{
            width:100%!important;
            word-break: break-all;
        }
        ::v-deep img{
            max-width:100%!important;
        }
        ::v-deep video{
            width:100%!important;
        }
    }
}   
</style>
